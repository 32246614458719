import * as React from 'react';
import * as ReactDropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { useTypedFetcher } from 'remix-typedjson';
import { Avatar } from '~/cds/Avatar';
import { URLS } from '~/common/constants/urls';
import { cn } from '~/common/utils/styles';
import { ImageUploadType, type FileType } from '~/common/utils/upload';
import type { action } from '~/routes/actions.profile.upload-image/route';
import { UploadOverlay } from './UploadOverlay';

export const ProfileAvatar = ({
    avatarURL,
    displayNameSlug,
    wrapperClassName,
}: {
    avatarURL: string;
    displayNameSlug: string;
    wrapperClassName?: string;
}) => {
    const [avatarImg, setAvatarImg] = React.useState<FileType[]>([]);
    const fetcher = useTypedFetcher<typeof action>();

    const onDrop = React.useCallback(
        async (
            acceptedFiles: File[],
            fileRejections: ReactDropzone.FileRejection[]
        ) => {
            if (fileRejections && fileRejections.length > 0) {
                const rejectionsString = fileRejections
                    .map(
                        (rejection: ReactDropzone.FileRejection) =>
                            rejection.file.name
                    )
                    .join(', ');

                toast.error('File types not supported: ' + rejectionsString);
            }
            const newFiles = acceptedFiles.map((file: File) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );

            setAvatarImg(newFiles);

            if (newFiles.length > 0) {
                const formData = new FormData();
                formData.append('photo', newFiles[0]);
                formData.append('display_name_slug', displayNameSlug);
                formData.append('type', ImageUploadType.AVATAR);

                fetcher.submit(formData, {
                    method: 'post',
                    encType: 'multipart/form-data',
                    action: URLS.ACTION.PROFILE.UPLOAD_IMAGE,
                });
            }
        },
        [setAvatarImg, fetcher, displayNameSlug]
    );

    const {
        getRootProps,
        getInputProps,
        open: uploadAvatar,
    } = ReactDropzone.useDropzone({
        onDrop,
        noClick: true,
        multiple: false,
        accept: {
            'image/png': ['.png', '.heic'],
            'image/jpeg': ['.jpg', '.jpeg'],
        },
    });

    const isSubmitting = ['loading', 'submitting'].includes(fetcher.state);

    return (
        <div
            {...getRootProps()}
            className={cn(
                'absolute rounded-full border-8 border-white bg-white shadow-small',
                wrapperClassName
            )}
        >
            <div
                className="group relative h-full w-full overflow-hidden rounded-full"
                onClick={() => {
                    if (fetcher.state === 'idle') {
                        uploadAvatar();
                    }
                }}
            >
                <Avatar
                    src={
                        avatarImg.length > 0 ? avatarImg[0].preview : avatarURL
                    }
                    className="h-full w-full"
                />
                <input {...getInputProps()} />
                <UploadOverlay loading={isSubmitting} />
            </div>
        </div>
    );
};
