import * as React from 'react';
import { Navbar } from '~/cds/Navbar';
import type { User } from '~/modules/users/user.types';

interface PortalLayoutProps {
    user: User;
    children: React.ReactNode;
}

export const PortalLayout: React.FC<PortalLayoutProps> = ({
    user,
    children,
}) => {
    return (
        <div className="flex min-h-screen flex-col">
            <Navbar user={user} />
            {children}
        </div>
    );
};
