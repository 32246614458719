import { AiFillCamera } from 'react-icons/ai';
import { LoadingIndicator } from '~/cds/LoadingIndicator';

export const UploadOverlay = ({ loading }: { loading: boolean }) => {
    return loading ? (
        <div className="absolute inset-0 grid place-content-center bg-black/40 transition-all duration-150 ease-in-out">
            <LoadingIndicator />
        </div>
    ) : (
        <div className="absolute inset-0 grid place-content-center bg-black/40 opacity-0 transition-all duration-150 ease-in-out group-hover:cursor-pointer group-hover:opacity-100">
            <AiFillCamera className="h-7 w-7 text-white" />
        </div>
    );
};
