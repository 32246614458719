import { Link, NavLink } from '@remix-run/react';
import { useActiveFeatureFlags } from 'posthog-js/react';
import { URLS } from '~/common/constants/urls';
import { FEATURE_KEYS } from '~/common/utils/posthog';
import type { User } from '~/modules/users/user.types';
import { Button } from '../Button';
import { ProfileSidePanel } from './components';

interface NavLinks {
    name: string;
    path: string;
    feature?: string;
}

const navLinks: NavLinks[] = [
    { name: 'Home', path: URLS.HOME },
    {
        name: 'Governance',
        feature: FEATURE_KEYS.GOVERNANCE,
        path: URLS.GOVERNANCE,
    },
    { name: 'Treasury', feature: FEATURE_KEYS.TREASURY, path: URLS.TREASURY },
    {
        name: 'Leaderboard',
        feature: FEATURE_KEYS.LEADERBOARD,
        path: URLS.LEADERBOARD,
    },
    {
        name: 'Community',
        feature: FEATURE_KEYS.COMMUNITY,
        path: URLS.COMMUNITY,
    },
    {
        name: 'Office Hours',
        path: URLS.OFFICE_HOURS,
    },
];

export const Navbar = ({ user }: { user: Partial<User> }) => {
    const activeFeatures = useActiveFeatureFlags();
    return (
        <div className="sticky top-0 z-50 flex items-center justify-between border-b border-gray-800 bg-white py-4 px-10">
            <div className="flex flex-1 items-center gap-4 md:gap-10">
                <Link to={URLS.PUBLIC.ROOT} className="inline-block shrink-0">
                    <img
                        loading="eager"
                        src="/images/logo/creatordao_black_logo.svg"
                        alt="logo"
                        width="79"
                        height="40"
                    />
                </Link>
                <div className="flex items-center">
                    {navLinks
                        .filter(
                            (link) =>
                                !link.feature ||
                                (activeFeatures &&
                                    activeFeatures.includes(link.feature))
                        )
                        .map((link) => (
                            <NavLink
                                to={link.path}
                                key={link.path}
                                prefetch="render"
                            >
                                {({ isActive }) => (
                                    <Button
                                        variant="tertiary"
                                        size="md"
                                        selected={isActive}
                                    >
                                        {link.name}
                                    </Button>
                                )}
                            </NavLink>
                        ))}
                </div>
            </div>
            <div className="flex items-center gap-3">
                <Button as={Link} to={URLS.CONTRIBUTE}>
                    Contribute to DAO
                </Button>
                <ProfileSidePanel
                    avatarURL={user.photo_url || ''}
                    displayName={user.display_name || ''}
                    displayNameSlug={user.display_name_slug || ''}
                    coverPhotoURL={user.cover_photo_url || ''}
                />
            </div>
        </div>
    );
};
