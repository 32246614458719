import { Dialog, Transition } from '@headlessui/react';
import { HomeIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from '@remix-run/react';
import * as React from 'react';
import { MdOutlineDashboard, MdOutlineLocalActivity } from 'react-icons/md';
import { Button } from '~/cds/Button';
import { URLS } from '~/common/constants/urls';

const navigation = [
    {
        name: 'Dashboard',
        to: URLS.ADMIN.DASHBOARD,
        icon: MdOutlineDashboard,
    },
    {
        name: 'Activities',
        to: URLS.ADMIN.ACTIVITIES.ROOT,
        icon: MdOutlineLocalActivity,
    },
    {
        name: 'Applications',
        to: URLS.ADMIN.APPLICATIONS,
        icon: UsersIcon,
    },
];

interface AdminSidebarDesktopProps {
    pathname: string;
}

export const AdminSidebarDesktop = ({ pathname }: AdminSidebarDesktopProps) => {
    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-800 bg-white px-6 py-10 pb-4">
                <Link to={URLS.HOME} className="flex shrink-0 px-2">
                    <img
                        src="/images/landing/cdao_landing_logo.svg"
                        alt="CreatorDAO Logo"
                        width="198"
                        height="18"
                        className="h-8 w-auto invert"
                    />
                </Link>
                <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul className="-mx-2 space-y-1">
                                {navigation.map((item, index) => (
                                    <Button
                                        key={index}
                                        as={Link}
                                        to={item.to}
                                        variant="tertiary"
                                        size="md"
                                        selected={item.to === pathname}
                                        className="w-full justify-start"
                                    >
                                        <item.icon
                                            className="h-6 w-6 shrink-0"
                                            aria-hidden="true"
                                            height={24}
                                            width={24}
                                        />
                                        {item.name}
                                    </Button>
                                ))}
                            </ul>
                        </li>
                        <li className="mt-auto">
                            <Button
                                as={Link}
                                to={URLS.HOME}
                                variant="tertiary"
                                size="md"
                                className="w-full justify-start"
                            >
                                <HomeIcon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                    height={24}
                                    width={24}
                                />
                                Back to Home
                            </Button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

interface AdminSidebarMobileProps {
    pathname: string;
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminSidebarMobile = ({
    pathname,
    sidebarOpen,
    setSidebarOpen,
}: AdminSidebarMobileProps) => {
    return (
        <Transition.Root show={sidebarOpen} as={React.Fragment}>
            <Dialog
                as="div"
                className="relative z-50 lg:hidden"
                onClose={setSidebarOpen}
            >
                <Transition.Child
                    as={React.Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                    <Transition.Child
                        as={React.Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                            <Transition.Child
                                as={React.Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button
                                        type="button"
                                        className="-m-2.5 p-2.5"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">
                                            Close sidebar
                                        </span>
                                        <XMarkIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-10 pb-4">
                                <Link
                                    to={URLS.HOME}
                                    className="flex shrink-0 px-2"
                                >
                                    <img
                                        src="/images/landing/cdao_landing_logo.svg"
                                        alt="CreatorDAO Logo"
                                        width="198"
                                        height="18"
                                        className="h-8 w-auto invert"
                                    />
                                </Link>
                                <nav className="flex flex-1 flex-col">
                                    <ul className="flex flex-1 flex-col gap-y-7">
                                        <li>
                                            <ul className="-mx-2 space-y-1">
                                                {navigation.map(
                                                    (item, index) => (
                                                        <Button
                                                            key={index}
                                                            as={Link}
                                                            to={item.to}
                                                            variant="tertiary"
                                                            size="md"
                                                            selected={
                                                                item.to ===
                                                                pathname
                                                            }
                                                            className="w-full justify-start"
                                                        >
                                                            <item.icon
                                                                className="h-6 w-6 shrink-0"
                                                                aria-hidden="true"
                                                                height={24}
                                                                width={24}
                                                            />
                                                            {item.name}
                                                        </Button>
                                                    )
                                                )}
                                            </ul>
                                        </li>
                                        <li className="mt-auto">
                                            <Button
                                                as={Link}
                                                to={URLS.HOME}
                                                variant="tertiary"
                                                size="md"
                                                className="w-full justify-start"
                                            >
                                                <HomeIcon
                                                    className="h-6 w-6 shrink-0"
                                                    aria-hidden="true"
                                                    height={24}
                                                    width={24}
                                                />
                                                Back to Home
                                            </Button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
