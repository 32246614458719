import * as React from 'react';
import { cn } from '../utils/styles';

interface RightSidebarProps {
    children: React.ReactNode;
    className?: string;
}

export const NarrowSection: React.FC<RightSidebarProps> = ({
    children,
    className,
}) => {
    return (
        <div
            className={cn(
                'relative w-3/5 flex-1 space-y-spacer-32 p-10 xl:w-2/3',
                className
            )}
        >
            {children}
        </div>
    );
};
