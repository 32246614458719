import * as React from 'react';
import { cn } from '../utils/styles';

interface LeftSidebarProps {
    children: React.ReactNode;
    className?: string;
}

export const WideSection: React.FC<LeftSidebarProps> = ({
    children,
    className,
}) => {
    return (
        <div
            className={cn(
                'w-2/5 max-w-xl divide-y divide-gray-800 xl:w-1/3',
                className
            )}
        >
            {children}
        </div>
    );
};
