import * as React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../cds/Button';

interface SlideInPanelProps {
    triggerContent: React.ReactNode;
    children: React.ReactNode;
}

export const SlideInPanel = ({
    triggerContent,
    children,
}: SlideInPanelProps) => {
    const [open, setOpen] = React.useState(false);

    return (
        <Dialog.Root onOpenChange={(o) => setOpen(o)}>
            <Dialog.Trigger asChild>{triggerContent}</Dialog.Trigger>
            <AnimatePresence>
                {open ? (
                    <Dialog.Portal forceMount>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Dialog.Overlay
                                forceMount
                                className="fixed inset-0 z-50 bg-black/20 transition-all"
                            />
                        </motion.div>
                        <motion.nav
                            initial={{ x: '100%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%' }}
                            transition={{ duration: 0.3 }}
                            className="fixed inset-0 z-50 flex items-start justify-center sm:items-center"
                        >
                            <div className="absolute left-0 top-0 w-16 justify-center pt-5 xs:flex sm:hidden">
                                <Button
                                    variant="tertiary"
                                    iconOnly={
                                        <XMarkIcon className="h-full w-full text-black" />
                                    }
                                    onClick={() => setOpen(false)}
                                />
                            </div>
                            <Dialog.Content
                                forceMount
                                className="shadow-left fixed top-0 bottom-0 right-0 z-50 flex flex-col gap-4 bg-white xs:w-[calc(100vw-64px)] sm:w-full sm:max-w-md"
                            >
                                {children}
                            </Dialog.Content>
                        </motion.nav>
                    </Dialog.Portal>
                ) : null}
            </AnimatePresence>
        </Dialog.Root>
    );
};
