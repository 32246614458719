import * as Dialog from '@radix-ui/react-dialog';
import { useNavigate } from '@remix-run/react';
import { ChevronRight } from 'lucide-react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { FaUser } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import { useTypedFetcher } from 'remix-typedjson';
import { ListItem } from '~/cds/ListItem';
import { Typography } from '~/cds/Typography';
import { URLS } from '~/common/constants/urls';

const links = [
    {
        icon: <FaUser className="h-5 w-5" />,
        text: 'Account',
        path: URLS.SETTINGS.ACCOUNT,
    },
    {
        icon: <AiTwotoneCalendar className="h-5 w-5" />,
        text: 'Office Hours',
        path: URLS.SETTINGS.OFFICE_HOURS,
    },
    {
        icon: <MdLogout className="h-5 w-5" />,
        text: 'Logout',
        path: URLS.LOGOUT,
    },
];

export const SidePanelLinks = () => {
    const navigate = useNavigate();
    const fetcher = useTypedFetcher();

    return (
        <div className="px-2">
            {links.map((link, index) => (
                <Dialog.Close key={index} className="w-full">
                    <ListItem
                        onClick={() => {
                            if (link.path === URLS.LOGOUT) {
                                fetcher.submit(
                                    {},
                                    {
                                        method: 'POST',
                                        action: URLS.LOGOUT,
                                    }
                                );
                            } else {
                                navigate(link.path);
                            }
                        }}
                    >
                        <div className="flex items-center gap-4">
                            {link.icon}
                            <Typography className="font-medium leading-5">
                                {link.text}
                            </Typography>
                        </div>
                        <div className="text-right">
                            <ChevronRight className="h-5 w-5 text-gray-200" />
                        </div>
                    </ListItem>
                </Dialog.Close>
            ))}
        </div>
    );
};
