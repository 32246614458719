import * as Dialog from '@radix-ui/react-dialog';
import { useNavigate } from '@remix-run/react';
import { X } from 'lucide-react';
import { Avatar } from '~/cds/Avatar';
import { Button } from '~/cds/Button';
import { Typography } from '~/cds/Typography';
import { SlideInPanel } from '~/common/components/SlideInPanel';
import { URLS } from '~/common/constants/urls';
import { ProfileAvatar, ProfileCover, SidePanelLinks } from './components';

export const ProfileSidePanel = ({
    avatarURL,
    displayName,
    displayNameSlug,
    coverPhotoURL,
}: {
    avatarURL: string;
    displayName: string;
    displayNameSlug: string;
    coverPhotoURL: string;
}) => {
    const navigate = useNavigate();

    return (
        <SlideInPanel
            triggerContent={
                <div className="inline-block cursor-pointer">
                    <Avatar src={avatarURL} variant="md" />
                </div>
            }
        >
            <div className="relative h-56">
                <ProfileCover
                    coverPhotoURL={coverPhotoURL}
                    displayNameSlug={displayNameSlug}
                />
                <Dialog.Close className="absolute top-4 right-5 outline-none">
                    <Button
                        variant="tertiary"
                        size="md"
                        iconOnly={<X className="h-5 w-5" />}
                        as="div"
                    />
                </Dialog.Close>
                <ProfileAvatar
                    avatarURL={avatarURL}
                    displayNameSlug={displayNameSlug}
                    wrapperClassName="left-5 -bottom-8 h-32 w-32"
                />
            </div>
            <div className="no-scrollbar flex-1 overflow-y-auto pt-16 pb-4">
                <div className="mb-10 space-y-spacer-24 px-5">
                    <div className="space-y-spacer-12">
                        <Typography
                            intent="display"
                            size="xs"
                            className="uppercase"
                        >
                            {displayName}
                        </Typography>
                        <Typography
                            intent="body"
                            size="xl"
                            className="text-gray-500"
                        >
                            @{displayNameSlug}
                        </Typography>
                    </div>
                    <Dialog.Close
                        onClick={() =>
                            navigate(URLS.PROFILE + '/' + displayNameSlug)
                        }
                    >
                        <Button as="div" variant="secondary" size="sm">
                            View Profile
                        </Button>
                    </Dialog.Close>
                </div>
                <SidePanelLinks />
            </div>
        </SlideInPanel>
    );
};
