import { Footer, Navbar } from '~/common/components/Landing';

interface MainLayoutProps {
    children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
    return (
        <div className="flex min-h-screen flex-col">
            <Navbar />
            {children}
            <Footer />
        </div>
    );
};
